html {
    // font-size: 10px;
    font-size   : 16px;
}

body {
    margin                 : 0;
    padding                : 0;
    font                   : 100% $font-stack;
    -webkit-font-smoothing : antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-box-sizing     : border-box;
    box-sizing             : border-box;
}

a,
ul {
    text-decoration: none;
}

ul {
    list-style: none;
}

*,
*::after,
*::before {
    margin            : 0;
    padding           : 0;
    -webkit-box-sizing: inherit;
    box-sizing        : inherit;
}

main {
    padding: 4rem;
}

/*# sourceMappingURL=base.css.map */

svg {
    display: block;
    width  : 1em;
    height : 1em;
    fill   : currentColor;
}

@keyframes fadeIn {

    0% {

        opacity: 0;
        height : 0;
    }

    100% {
        opacity: 1;
        height : 100%;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        height : 100%;
    }

    100% {
        opacity: 0;
        height : 0;
    }
}