//Fonts
$default-fontSize:1.25rem;
$font-stack      :-apple-system,
BlinkMacSystemFont,
"Segoe UI",
"Roboto",
"Oxygen",
"Ubuntu",
"Cantarell",
"Fira Sans",
"Droid Sans",
"Helvetica Neue",
sans-serif;
$header-color:#efe3e3;
$break-small: 920px;
$break-large: 1024px;