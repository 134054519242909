.menubar-area {
  .container {
    padding: 0 5rem;
    background: #476edc;
  }

  .navbar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row nowrap;

    @include respond-to(handhelds) {
      flex-flow: column nowrap;
    }

    @include respond-to(medium-screens) {
      flex-flow: row nowrap;
    }

    @include respond-to(wide-screens) {
      flex-flow: row nowrap;
    }

    &-brand {
      font-weight: 600;
      font-size: 1.3rem;
      display: flex;
      align-items: center;
      padding: 1rem;
      width: 100%;
      flex: 1;

      a {
        color: $header-color;
        flex: 2;
        @include respond-to(handhelds) {
          text-align: center;
        }
      }
    }

    &-toggler {
      &-icon {
        font-size: 2rem;
        color: #fff;
        cursor: pointer;

        @include respond-to(handhelds) {
          display: block;
        }

        @include respond-to(medium-screens) {
          display: none;
        }

        @include respond-to(wide-screens) {
          display: none;
        }
      }
    }

    .show {
      transition: opacity 0.2s, height 0.3s;
      height: 65vh;
      opacity: 1;
    }

    .hide {
      transition: opacity 0.2s, height 0.3s;
      height: 0;
      opacity: 0;
    }

    .navbar--fadeOut {
      transition: all 2s;
      animation: fadeOut 2s;
    }

    .navbar--fadeInUp {
      transition: all 2s;
      animation: fadeIn 2s;
    }

    &-collapse {
      display: flex;
      flex: 2;
      // @include respond-to(handhelds) {
      //     display: none;
      // }

      // @include respond-to(medium-screens) {
      //     display  : flex;
      //     flex     : 2;
      // }

      // @include respond-to(wide-screens) {
      //     display  : flex;
      //     flex     : 2;
      // }
    }

    &-nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: auto;

      @include respond-to(handhelds) {
        flex-direction: column;
      }

      @include respond-to(medium-screens) {
        flex-direction: row;
      }

      @include respond-to(wide-screens) {
        flex-direction: row;
      }

      li {
        display: flex;
        justify-content: center;
        align-items: center;

        a {
          padding: 1rem;
          font: 100% $font-stack;
          color: $header-color;
          font-weight: 600;
          transition: all ease 0.1s;

          &:hover {
            transform: scale(1.1);
            background: #5584da;
          }
        }
      }
    }
  }
}
